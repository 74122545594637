import React, { useEffect, useState, useRef } from "react"; import UserAlreadyExists from '../Components/Assets/already exists.jpg';
import { HiOutlineLightBulb, HiOutlineCurrencyDollar, HiOutlineUser, HiOutlineBookOpen, } from "react-icons/hi";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation, Pagination } from "swiper";
import MGR from '../Components/Assets/MGR.jpg'
import Curriculum from "../Components/Assets/Curriculum.webp"
import NameBanner from '../Components/Assets/pharmacynamebanner.png'
import MobileNameBanner from '../Components/Assets/mobilepharnamebanner.png'
import axios from "axios";
import "react-responsive-modal/styles.css";
import { MBADM } from "../Components/Json/MBA-DM"
import { BsCheck2All } from "react-icons/bs";
import $ from 'jquery';

function CoursePage() {
    const scrollHideDiv = useRef(null)
    const reference = useRef()
    const [userExists, setUserExists] = useState(false)

    const [values, setValue] = useState({
        name: "",
        email: "",
        phone: "",
        course: "Doctor of Pharmacy (Pharm.D)",
        parameter: ""
    })

    userExists ? document.body.style.overflow = "hidden" : document.body.style.overflow = "auto";

    useEffect(() => {
        document.addEventListener("mousedown", (event) => {
            if (!reference.current.contains(event.target)) {
                setUserExists(false)
            }
        })
    })

    const [errors, setErrors] = useState({})
    const [isSaveClick, setIsSaveClick] = useState(false)
    useEffect(() => {

        window.addEventListener("scroll", (event) => {
            var y = window.scrollY;
            if (y >= 600) {
                $("#scrolling").fadeIn("slow");
            } else if (y <= 600) {
                $("#scrolling").fadeOut(2000);
            }
        })
    }, [])

    const [urlAds, seturlAds] = useState("Lead")
    const date = new Date().getFullYear()

    useEffect(() => {
        var url = window.location.href;
        url = url.replace("?", '');
        var newnul = url.split(/([_,=,&,-,-,+,/,:])/)
        newnul.forEach((el) => {
            const element = el;
            switch (element) {
                case "ads":
                    seturlAds("ads")
                    break;
                case "google":
                    seturlAds("google")
                    break;
                case "Google":
                    seturlAds("google")
                    break;
                case "gooGle":
                    seturlAds("google")
                    break;
                case "GooGle":
                    seturlAds("google")
                    break;
                case "youtube":
                    seturlAds("youtube")
                    break;
                case "Youtube":
                    seturlAds("youtube")
                    break;
                case "youTube":
                    seturlAds("youtube")
                    break;
                case "YouTube":
                    seturlAds("youtube")
                    break;
                case "facebook":
                    seturlAds("facebook")
                    break;
                case "Facebook":
                    seturlAds("facebook")
                    break;
                case "FaceBook":
                    seturlAds("facebook")
                    break;
                case "faceBook":
                    seturlAds("facebook")
                    break;
                case "Instagram":
                    seturlAds("Instagram")
                    break;
                case "InstaGram":
                    seturlAds("Instagram")
                    break;
                case "instagram":
                    seturlAds("Instagram")
                    break;

                default:
                    break;
            }

        })
    }, [])

    const handleChange = (e) => {
        const { name, value } = e.target
        setValue({
            ...values,
            [name]: value
        })
        if (isSaveClick === true) {
            setErrors(errorHandler(values))
        }
    }


    const errorHandler = () => {
        var error = {}
        let regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
        let Number = /^[0-9]/
        if (values.name === "") {
            error.name = "Enter the Name"
        }
        if (values.email === "") {
            error.email = "Enter the Email"
        } else if (!regex.test(values.email)) {
            error.email = "Invalid email type"
        }
        if (values.phone === "") {
            error.phone = "Enter the phone"
        } else if (!Number.test(values.phone)) {
            error.phone = "must be number "
        } else if (values.phone.length !== 10) {
            error.phone = "must be number 10 number"
            values.phone = ""
        }
        if (values.course === "") {
            error.course = "Enter the course"
        }
        return error
    }


    const handleSubmit = (event) => {
        event.preventDefault();
        setIsSaveClick(true)
        var noerror = errorHandler(values)
        setErrors(noerror)
        if (Object.keys(noerror).length === 0) {
            var data = {};
            const params = new URLSearchParams({
                name: values.name,
                email: values.email,
                phone: values.phone,
                course: values.course,
                parameter: urlAds,
            }).toString();

            var btm = document.getElementById('submit')
            btm.disabled = true

            // console.log("https://rvscas.com/api/createadmission?" + params)
            const url = "https://rvscas.com/api/createadmission?" + params;
            // const url = "http://192.168.101.156:7000/api/createadmission?" + params;
            // console.log(values.parameter)
            axios.post(url, data)
                .then((response) => {
                    const toDataURL = url => fetch(url)
                        .then(response => response.blob())
                        .then(blob => new Promise((resolve, reject) => {
                            const reader = new FileReader()
                            reader.onloadend = () => resolve(reader.result)
                            reader.onerror = reject
                            reader.readAsDataURL(blob)
                        }))

                    toDataURL(require('../Components/Assets/PharmD.pdf'))

                        .then(dataUrl => {
                            localStorage.setItem("PharmD", "ppphhhaaarrrmmmDDD")
                            window.location = "/thankyou"
                            const element = document.createElement('a');
                            element.setAttribute('download', 'Doctor of Pharmacy');
                            element.setAttribute('href', dataUrl);
                            element.style.display = 'none';
                            document.body.appendChild(element);
                            element.click();
                            document.body.removeChild(element);
                            setErrors({});
                        })
                    setValue({
                        name: "",
                        email: "",
                        phone: "",
                        course: "",
                    })
                    setErrors({})

                })
                .catch((error) => {
                    console.log(error)
                    if (error.response.status === 409) {
                        setUserExists(true)
                        setTimeout(() => {
                            setUserExists(false)
                        }, 8000)
                    }
                    btm.disabled = false
                })
        }
    };

    return (

        <>

            <div className="relative">
                {userExists === true ? <div className="justify-center bg-modal backdrop-blur-sm items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none sm:w-[100%] sm:px-3 ">
                    <div className=" scale-up-center" ref={reference}>
                        <img src={UserAlreadyExists} alt="fail" className="w-[550px] rounded-lg" />
                    </div>
                </div>
                    : <div></div>}
                <header className="category-title" id="header">
                    <div className="h-[100vh] relative " id="form">
                        <nav className="">
                            <div className="container mx-auto ">
                                <div className="center desktop-view-logo">
                                    <div className="w-[100%] center">
                                        <img src={NameBanner} alt="RVS Logo" className="logoCss mt-[10px] w-[60%] sm:w-[10%] pt-3 mx-auto" />
                                    </div>
                                </div>
                                <div className="flex  mobile-view w-[100%]">
                                    <div className="mobile-view-logo">
                                        <div className="w-[100%] center ">
                                            <img src={MobileNameBanner} alt="RVS Logo" className="logoCss mobile-view-image sm:w-[90%] md:w-[60%] mdsm:w-[60%] py-4 mx-auto" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </nav>
                        <div className="backgroundimage h-[100%] object-cover">
                        </div>
                        <div className="absolute bottom-[0%] sm:top-[16%] Hero w-[100%] lg:top-[24%] lge-[10%] lg:bottom-none md:top-[20%] " >
                            <div className="container mx-auto py-16 sm:py-0 mdsm:py-0">
                                <div className="w-[100%] flex sm:block md:block mdsm:block lg:flex" >
                                    <div className="w-[60%] center content sm:none sm:w-[100%] lg:w-[60%] md:w-[100%] mdsm:w-[90%] mdsm:mx-auto md:mx-auto md:pb-0 mdsm:pb-0">
                                        <div className="block sm:px-0 sm:w-[80%] md:-[80%] lg:pt-4 mdsm:w-[80%] lg:w-[100%] mdsm:mx-auto sm:mx-auto sm:pb-0">
                                            <p className="text-white title md:text-[24px] text-[48px] mdsm:text-[24px]  lg:text-[30px] text-left sm:text-[16px]">
                                                Admissions Open for the Year 2023-24
                                            </p>
                                            <h1 className="text-white title md:text-[20px] text-[48px] mdsm:text-[20px] lg:text-[40px] text-left sm:text-[20px]">{MBADM?.title} </h1>
                                            {/* <h1 className="text-white title md:text-[20px] text-[48px] mdsm:text-[20px] lg:text-[28px] text-left sm:text-[20px]">Specialization in Pharmaceutics, Pharmaceutical Analysis, & Pharmacology</h1> */}
                                            <div className="w-[100%] mdsm:none mdsm:pt-2 ">

                                                <p className="sm:visible lg:hidden text-white text-h2 md:text-[18px] sm:pt-4  lg:text-[16px] lg:leading-[30px] mdsm:text-[16px] sm:text-[14px] sm:leading-[20px] leading-[36px] mdsm:leading-[26px] md:leading-[26px] text-justify pb-2">Embrace the power of healing and ignite your passion for pharmacy.</p>
                                                {/* <p className="text-white text-h2 md:text-[16px] NoneCss mdsm:leading-[26px] lg:text-[20px] lg:leading-[30px] mdsm:text-[16px] sm:text-[14px] sm:leading-[20px] leading-[36px] md:leading-[24px] text-justify">{MBADM?.course_dis}</p> */}
                                                <p className="text-white text-h2 md:text-[16px] NoneCss mdsm:leading-[26px] lg:text-[20px] lg:leading-[30px] mdsm:text-[16px] sm:text-[14px] sm:leading-[20px] leading-[36px] md:leading-[24px] text-justify">The Pharm.D. program is designed to train students in clinical pharmacy practice, emphasizing medication management and patient care. Through this program, students develop the necessary skills to become pharmacy practitioners, deliver direct healthcare services, and advocate for rational medication use. Join us to embark on a rewarding journey in the pharmacy field, where you'll positively impact patient outcomes and contribute to improving healthcare outcomes.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="w-[40%] sm:w-[100%] md:w-[100%] md:mx-auto  mdsm:w-[90%] mdsm:mx-auto lg:w-[40%]">
                                        <div className="flex lg:justify-center mdsm:justify-center  sm:justify-center md:justify-center">
                                            <form className="w-[80%] py-2" >
                                                <div>
                                                    <div className="bg-primary py-2 px-8">
                                                        <p className="text-center text-white text-[24px] sm:text-[16px] title">Get Your Brochure</p>
                                                    </div>
                                                    <div className="px-8 py-8 lg:py-8 lg:px-8 sm:px-6 sm:py-6 bg-pureWhite md:px-2 md:py-2 mdsm:py-4 mdsm:px-4">
                                                        <div className="mb-6 sm:mb-2 md:mb-2 mdsm:md-2 marginLabel lg:mb-6">
                                                            <input
                                                                type="text"
                                                                name="name"
                                                                id="name"
                                                                value={values.name}
                                                                onChange={(e) => handleChange(e)}
                                                                placeholder={`${errors.name ? "Enter Your Name" : "Name"}`}
                                                                className={`borderColor ${errors.name ? "text-red placeholder:text-red error" : ""} name px-4 focus:outline-none focus:border-primary w-[100%] h-[40px] sm:h-[30px] md:h-[30px] mdsm:h-[30px] lg:h-[40px] sm:text-sm placeholder:text-text-color`}
                                                            />
                                                            <div className="flex justify-items-start px-4 pt-2">
                                                                {/* {errors.name && <span className="text-red text-sm sm:text-[10px] text-center">{errors.name}</span>} */}
                                                            </div>
                                                        </div>

                                                        <div className="mb-6 sm:mb-2 md:mb-2 mdsm:md-2 marginLabel  lg:mb-6">
                                                            <input
                                                                type="text"
                                                                name="email"
                                                                id="email"
                                                                value={values.email}
                                                                onChange={(e) => handleChange(e)}
                                                                placeholder={`${errors.email ? "Enter Your Email ID" : "Email"}`}
                                                                className={`borderColor ${errors.email ? "error" : ""} email px-4 focus:outline-none  focus:border-primary w-[100%] h-[40px] sm:h-[30px] md:h-[30px] mdsm:h-[30px] lg:h-[40px] placeholder:text-text-color sm:text-sm`} />
                                                            <div className="flex justify-items-start px-4 pt-2">
                                                                {/* {errors.email && <span className="text-red text-sm sm:text-[10px] text-center">{errors.email}</span>} */}
                                                            </div>
                                                        </div>
                                                        <div className="mb-6 sm:mb-2 md:mb-2 mdsm:md-2 marginLabel  lg:mb-6">
                                                            <input type="text"
                                                                name="phone"
                                                                id="phone"
                                                                value={values.phone}
                                                                placeholder={`${errors.phone ? "Enter Your Mobile Number" : "Mobile Number"}`}
                                                                className={`borderColor ${errors.phone ? "placeholder:text-red error" : ""} phone px-4 focus:outline-none focus:border-primary w-[100%] h-[40px] sm:h-[30px] md:h-[30px] mdsm:h-[30px] lg:h-[40px] placeholder:text-text-color sm:text-sm`}
                                                                onChange={(e) => handleChange(e)}
                                                                onKeyPress={(e) => { if (!/^[0-9]/.test(e.key)) { e.preventDefault() } }}
                                                                maxLength={10}
                                                                minLength={10}
                                                            />
                                                            <div className="flex justify-items-start px-4 pt-2">
                                                                {/* {errors.phone && <span className="text-red text-sm sm:text-[10px] text-center">{errors.phone}</span>} */}
                                                            </div>
                                                        </div>
                                                        {/* <div className="mb-6 sm:mb-2 md:mb-2 mdsm:md-2 marginLabel  lg:mb-6">
                                                                <input
                                                                    disabled
                                                                    type="text"
                                                                    name="course"
                                                                    value={values.course}
                                                                    onChange={(e) => handleChange(e)}
                                                                    placeholder={`${errors.course ? "Enter Your Course" : "Course"}`}
                                                                    className={`borderColor-disable ${errors.course ? "placeholder:text-red error" : ""} px-4 focus:outline-none bg-[#ccdaf5] focus:border-primary w-[100%] h-[40px] sm:h-[30px] md:h-[30px] mdsm:h-[30px] lg:h-[40px] placeholder:text-primary sm:text-sm`} />
                                                                <div className="flex justify-items-start px-4 pt-2">
                                                                    {errors.course && <span className="text-red text-sm sm:text-[10px] text-center">{errors.course}</span>}
                                                                </div>
                                                            </div> */}
                                                        <button onClick={handleSubmit} type="submit" id="submit"
                                                            disabled={values.name !== '' && values.email !== '' && values.phone !== '' ? false : true}
                                                            className={`${values.name !== '' && values.email !== '' && values.phone !== '' ? "bg-primary hover:bg-[#081224]" : 'cursor-not-allowed bg-[#CCCCCC] text-black'} submit btn Transition text-[18px] borderradius sm:text-[14px]  text-pureWhite  px-14 py-1 tracking-wider`}>Submit </button>
                                                    </div>
                                                </div>
                                            </form>

                                        </div>
                                        <div className="w-[80%] sm:visible para-mobile-view  lg:hidden mx-auto">
                                            <p className="sm:visible lg:hidden text-white text-h2 md:text-[18px] sm:pt-4  lg:text-[16px] lg:leading-[30px] mdsm:text-[16px] sm:text-[14px] sm:leading-[20px] leading-[36px] mdsm:leading-[26px] md:leading-[26px] text-justify pb-2">Empower your pharmaceutical career - Innovate, Discover, Heal.</p>

                                            {/* <p className=" text-white text-h2 md:text-[18px] sm:pt-4  lg:text-[16px] lg:leading-[30px] mdsm:text-[16px] sm:text-[14px] sm:leading-[20px] leading-[36px] mdsm:leading-[26px] md:leading-[26px] text-justify sm:hidden mdsm:block">Master of Social Work is a two-year full-time postgraduate program specializing in Human Resource Management, Medical & Psychiatric Social Work & Community Development, with the goal of improving the well-being of individuals, groups, and communities. The program's curriculum is structured around three key components: theory, fieldwork, and research. The program also emphasizes the cultivation of professional commitment, the development of diverse communication skills, and the promotion of continuous professional growth. Students are allowed to complete internship in a place of their choice.</p> */}
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>

                {/*Header Section  */}


                {/*Quick Info  Section  */}

                {/* <section className="category-title" id="about">
                        <div className="container mx-auto ">
                            <div className="flex w-[100%] lg:flex mdsm:flex-wrap sm:flex sm:flex-wrap py-10 QuickinfoBoxBorder">
                                <div className="w-[25%] mdsm:w-[50%] lg:w-[25%] lg:pb-0 sm:w-[48%] sm:pb-8 mdsm:pb-8   QuickinfoBorder">
                                    <div className="w-[100%] flex">
                                        <div className="w-[40%] flex justify-end px-6 py-1">
                                            <HiOutlineCalendar className="text-h3 text-primary" />
                                        </div>
                                        <div className="w-[60%]">
                                            <div className="block">
                                                <p className="title text-h4 text-left text-secondary pb-2 sm:text-[18px]">Course Starts </p>
                                                <p className="text-base text-text-color text-left sm:text-[14px]">{MBADM?.course_reg.course_start}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="w-[25%] sm:w-[48%] lg:w-[25%] lg:pb-0 mdsm:w-[50%]  mdsm:pb-8  sm:pb-8 smQuickinfoBorder">
                                    <div className="w-[100%] flex">
                                        <div className="w-[40%] flex justify-end px-6 py-1">
                                            <HiOutlineClock className="text-h3 text-primary" />
                                        </div>
                                        <div className="w-[60%]">
                                            <div className="block">
                                                <p className="title text-h4 text-left text-secondary pb-2 sm:text-[18px]">Duration </p>
                                                <p className="text-base text-text-color text-left sm:text-[14px]">{MBADM?.course_reg.duration}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="w-[25%]  sm:w-[48%] lg:w-[25%] lg:pb-0 mdsm:w-[50%] mdsm:pb-8 sm:pb-8 QuickinfoBorder">
                                    <div className="w-[100%] flex">
                                        <div className="w-[40%] flex justify-end px-6 py-1">
                                            <HiOutlineLocationMarker className="text-h3 text-primary" />
                                        </div>
                                        <div className="w-[60%]">
                                            <div className="block">
                                                <p className="title text-h4 text-left text-secondary pb-2 sm:text-[18px]">Location  </p>
                                                <p className="text-base text-text-color text-left sm:text-[14px]">{MBADM?.course_reg.location}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="w-[25%] sm:w-[48%] lg:w-[25%] lg:pb-0  mdsm:w-[50%] smQuickinfoBorder">
                                    <div className="w-[100%] flex">
                                        <div className="w-[40%] flex justify-end px-6 py-1">
                                            <HiOutlineUserRemove className="text-h3 text-primary" />
                                        </div>
                                        <div className="w-[60%]">
                                            <div className="block">
                                                <p className="title text-h4 text-left text-secondary pb-2 sm:text-[18px]">Specialization  </p>
                                                <p className="text-base text-text-color text-left sm:text-[14px]">{MBADM?.course_reg.specialization}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section> */}

                {/*ScolarShip and Accerdiation Section  */}

                <section className="category-title" id="ScolarShip">
                    <div className="container mx-auto ">
                        <div className="">
                            <div className="w-[100%] flex sm:block mdsm:px-8 lg:px-0 mdsm:block lg:flex">
                                {/*ScolarShip Section  */}
                                {/* <div className="w-[50%] sm:w-[100%] mdsm:w-[100%] md:w-[100%] lge:w-[100%] lg:w-[50%] ScolarShipPadding pl-6">
                                        <div className="flex">
                                            <img src={Batch} alt="ScrollShip Batch" />
                                            <p className="title text-secondary lg:text-[40px] mdsm:text-[30px] sm:text-left sm:text-[24px] py-3 pl-6">Scholarships</p>
                                        </div>
                                        <div className="pt-8">
                                            <h4 className="text-text-color text-justify leading-[34px] title text-[24px] sm:text-[18px]"> Avail special scholarships based on</h4>
                                            <div className="flex px-4 py-8">
                                                <ul>
                                                    {MBADM?.scholarships?.map((v, i) => {
                                                        return (
                                                            <li key={i} className="flex sm:text-sm md:text-[16px] mdsm:text-[16px] text-text-color leading-[26px] text-h5 pb-4"> <BsCheck2All className="text-[#00124d] sm:text-[18px] text-[22px] mx-4" />{v}</li>
                                                        )
                                                    })}
                                                </ul>
                                            </div>
                                        </div>
                                    </div> */}

                                {/*ScolarShip Section  */}

                                {/*ScolarShip and Accerdiation Section  */}

                                <div className="sm:w-[100%] mdsm:w-[100%] md:w-[100%] lge:w-[100%]  AccerditationBgImage">
                                    <div className="w-[100%] ScolarShipPadding">
                                        <div className="px-10 sm:px-0">
                                            <div className="flex w-[100%]">
                                                <p className="title tracking-wider sm:text-[24px] mdsm:text-[30px] text-pureWhite lg:text-[40px] py-3 ">Accreditation</p>
                                            </div>
                                            <div className="pt-8">
                                                {/* <p className="text-white text-justify sm:text-sm leading-[30px]">Lorem ipsum dolor sit consectetur adipiscing elit do eiusmod tempor incididunt ut labore et dolore.</p> */}
                                            </div>
                                            <div className="relative">
                                            </div>
                                            <Swiper
                                                slidesPerView={1}
                                                autoHeight={true}
                                                spaceBetween={10}
                                                navigation={true}
                                                pagination={{
                                                    clickable: true,
                                                }}
                                                modules={[Navigation, Pagination]}
                                                className="mySwiper"
                                                breakpoints={{
                                                    640: {
                                                        slidesPerView: 2,
                                                        spaceBetween: 20,
                                                    },
                                                    768: {
                                                        slidesPerView: 4,
                                                        spaceBetween: 40,
                                                    },
                                                    1024: {
                                                        slidesPerView: 2,
                                                        spaceBetween: 10,
                                                    },
                                                }}
                                            >
                                                <SwiperSlide><img src={MGR} alt="MGR" className="sm:mx-auto" /></SwiperSlide>
                                                {/* <SwiperSlide><img src={NeedImage} alt="Bharathiyar" className="sm:mx-auto" /></SwiperSlide> */}
                                                {/* <SwiperSlide><img src={AICTE} alt="AICTE" className="sm:mx-auto" /></SwiperSlide> */}
                                            </Swiper>
                                        </div>
                                    </div>
                                </div>
                                {/*ScolarShip and Accerdiation Section  */}
                            </div>
                        </div>
                    </div>
                </section>

                {/*Programs Highlighted Section  */}
                <div className="mt-10">
                </div>
                {/* <section className="category-title" id="Programs">
                        <div className="">
                            <div className="container mx-auto pb-10">
                                <div className="pt-10 sm:pt-2 mb-16">
                                    <div className="title lg:text-[40px] mdsm:text-[30px] sm:text-[24px] text-secondary">
                                        Program Highlights
                                    </div>
                                    <div className="W-[100%] flex  py-10 sm:block mdsm:flex-wrap lg:flex">
                                        <div className="w-[25%] sm:w-[100%] mdsm:w-[50%] lg:w-[25%]">
                                            <div className="bg-primary py-8  px-6 flex">
                                                <div className="w-[20%] sm:w-[5%] ">
                                                    <HiOutlineBookOpen className="text-white text-[50px] sm:text-[32px] sm:mx-auto" />
                                                </div>
                                                <div className="w-[80%] pl-8 sm:w-[95%]">
                                                    <p className="text-pureWhite text-left title text-[24px] sm:text-[20px]">World Class Curriculum</p>
                                                </div>
                                            </div>
                                            <img src={WorldClassCurriculum} alt="World Class Curriculum" className="w-[100%] mdsm:w-[100%]" />
                                        </div>
                                        <div className="w-[25%] sm:w-[100%] mdsm:w-[50%] lg:w-[25%]">
                                            <div className="bg-[#091B3F] py-8 px-6 flex">
                                                <div className="w-[20%] sm:w-[5%] sm:w-[10%]">
                                                    <HiOutlineClipboardList className="text-white text-[50px] sm:text-[32px] sm:mx-auto" />
                                                </div>
                                                <div className="w-[80%] pl-8  sm:w-[95%]">
                                                    <p className="text-pureWhite text-left title text-[24px] sm:text-[20px]">Project based Pedagogy</p>
                                                </div>
                                            </div>
                                            <img src={Projectbased} alt="Projec tbased" className="sm:w-[100%] mdsm:w-[100%]" />
                                        </div>
                                        <div className="w-[25%] sm:w-[100%] mdsm:w-[50%] lg:w-[25%]">
                                            <div className="bg-[#041028] py-8 px-6 flex">
                                                <div className="w-[20%] sm:w-[5%] sm:w-[10%]">
                                                    <HiOutlineOfficeBuilding className="text-white text-[50px] sm:text-[32px] sm:mx-auto" />
                                                </div>
                                                <div className="w-[80%] sm:w-[95%] pl-8">
                                                    <p className="text-pureWhite text-left title text-[24px] sm:text-[20px]">Industry Based Faculty</p>
                                                </div>
                                            </div>
                                            <img src={IndustryBased} alt="Industry Based" className="sm:w-[100%] mdsm:w-[100%]" />
                                        </div>
                                        <div className="w-[25%] sm:w-[100%] mdsm:w-[50%] lg:w-[25%]">
                                            <div className="bg-[#010917] py-8 px-6 flex">
                                                <div className="w-[20%] sm:w-[5%] sm:w-[10%]">
                                                    <HiOutlineMail className="text-white text-[50px] sm:text-[32px] sm:mx-auto" />
                                                </div>
                                                <div className="w-[80%] sm:w-[95%] pl-8">
                                                    <p className="text-pureWhite text-left title text-[24px] sm:text-[20px]">Internship Opportunities</p>
                                                </div>
                                            </div>
                                            <img src={InternshipOpportunities} alt="Internship Opportunities" className="sm:w-[100%] mdsm:w-[100%]" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section> */}
                {/*Programs Highlighted Section  */}


                {/*About Course Section  */}


                {/* <section className="category-title" id="programs-highlighted">
                        <div className="aboutCourse  sm:h-none  heightSetting ">
                            <div className="container mx-auto">
                                <div className="pt-10">
                                    <div className="">
                                        <div className="w-[100%] responsiveCenter sm:py-8  sm:pt-5  sm:p-4 mdsm:p-0 mdsm:py-16 lg:px-0">
                                            <div className="flex sm:block mdsm:block lg:flex">
                                                <div className="w-[40%] sm:w-[100%] mdsm:w-[100%] lg:w-[40%]">
                                                    <div className="title sm:text-center mdsm:text-center sm:pt-10 sm:pb-4 text-pureWhite sm:text-[24px] mdsm:text-[30px] lg:text-[48px]">About Specialization</div>
                                                    <div className='text-pureWhite center py-16 sm:text-left text-[20px] sm:text-[16px] leading-[30px]'>Students have the option to choose from the following specializations:</div>
                                                </div>
                                                <div className="w-[60%] px-16 sm:w-[100%] mdsm:w-[100%] mdsm:py-0 mdsm:px-4 lg:py-10 sm:px-0">
                                                    <p className="lg:text-[20px] mdsm:text-[16px] mdsm:pt-10 pb-6 sm:pt-4 text-pureWhite sm:text-[14px] sm:leading-[20px] leading-[36px] text-justify"><span className=" font-semibold underline underline-offset-4 pb-2">Pharmaceutics : </span> <p className="sm:pt-2"> Immerse yourself in pharmaceutical formulations, drug delivery systems, and drug development. Discover innovative approaches to enhance drug therapy and improve patient outcomes.</p></p>
                                                    <p className="lg:text-[20px] mdsm:text-[16px] mdsm:pt-0 pb-6 sm:pt-4 text-pureWhite sm:text-[14px] sm:leading-[20px] leading-[36px] text-justify"><span className=" font-semibold underline underline-offset-4">Pharmaceutical Analysis :</span> <p className="sm:pt-2"> Develop expertise in analyzing and ensuring the quality of pharmaceutical products. Gain practical experience in utilizing cutting-edge analytical techniques to guarantee the safety and effectiveness of medications.</p></p>
                                                    <p className="lg:text-[20px] mdsm:text-[16px] mdsm:pt-0 pb-6 sm:pt-4 text-pureWhite sm:text-[14px] sm:leading-[20px] leading-[36px] text-justify"><span className=" font-semibold underline underline-offset-4">Pharmacology :</span> <p className="sm:pt-2">Explore the complexities of drug interactions with the human body, studying the effects of drugs on physiological processes, and conducting research into the development of novel therapeutic strategies to combat diseases.</p></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </section> */}
                {/*About Course Section  */}


                {/*programme Section  */}

                <section className="category-title" id="about-course">
                    <div className="ProgrammeSection">
                        <div className="container mx-auto">
                            <div className="title sm:pt-10 lg:text-[40px] mdsm:text-[30px] sm:text-[24px] lg:pt-10 text-secondary">
                                Who should do the course?
                            </div>
                            <div className="w-[100%]  py-10 flex sm:pb-3 sm:block mdsm:flex-wrap">
                                <div className="w-[25%] group sm:w-[100%] mdsm:w-[50%] lg:w-[25%] px-6 sm:pb-8">
                                    <div className="flex justify-center">
                                        <HiOutlineBookOpen className="text-primary text-[98px] sm:text-[46px]" />
                                    </div>

                                    {/* <div className="text-text-color Transition group-hover:text-secondary sm:pt-2 text-center pt-4 sm:text-[16px] title text-[24px]">Accounts</div> */}
                                    <p className="text-text-color text-base pt-4 leading-[30px] lg:text-base sm:text-[14px] sm:pt-2 leading-wider">Pharmacists looking to specialize in a specific area of pharmacy practice.</p>
                                </div>
                                <div className="w-[25%] group px-6 sm:w-[100%] mdsm:w-[50%] lg:w-[25%] sm:pb-8">
                                    <div className="flex justify-center">
                                        <HiOutlineLightBulb className="text-primary text-[98px] sm:text-[60px]" />
                                    </div>
                                    {/* <div className="text-text-color Transition group-hover:text-secondary sm:pt-2 text-center pt-4 sm:text-[16px] title text-[24px]">Entrepreneur  </div> */}
                                    <p className="text-text-color text-base pt-4 leading-[30px] lg:text-base sm:text-[14px] sm:pt-2 leading-wider">Professionals seeking career advancement in the pharmaceutical industry or research organizations.
                                    </p>
                                </div>
                                <div className="w-[25%] group px-6 sm:w-[100%] mdsm:w-[50%] lg:w-[25%] sm:pb-8">
                                    <div className="flex justify-center">
                                        <HiOutlineCurrencyDollar className="text-primary text-[98px] sm:text-[44px]" />
                                    </div>
                                    {/* <div className="text-text-color Transition group-hover:text-secondary sm:pt-2 text-center pt-4 sm:text-[16px] title text-[24px]">Business Person </div> */}
                                    <p className="text-text-color text-base pt-4 leading-[30px] lg:text-base sm:text-[14px] sm:pt-2 leading-wider">Individuals interested in pursuing roles in drug development, regulatory affairs, or clinical pharmacy.
                                    </p>
                                </div>
                                <div className="w-[25%] group px-6 sm:w-[100%] mdsm:w-[50%] lg:w-[25%] sm:pb-8">
                                    <div className="flex justify-center">
                                        <HiOutlineUser className="text-primary text-[98px] sm:text-[50px]" />
                                    </div>
                                    {/* <div className="text-text-color Transition group-hover:text-secondary text-center pt-4 sm:pt-2 sm:text-[16px] title text-[24px]">Sales person </div> */}
                                    <p className="text-text-color text-base pt-4 sm:pt-2 leading-[30px] sm:text-[14px] leading-wider">Anyone passionate about the study of pharmaceutical sciences and its applications in healthcare.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/*programme Section  */}


                {/*Curriculum Section  */}
                {/* <InternShip /> */}


                <section className="category-title" id="curriculums">

                    <div className="CurriculumSection">
                        <div className="container mx-auto py-10 sm:py-4">
                            <div className="title pb-10 sm:pb-4 mdsm:text-[30px] text-left sm:text-center mdsm:text-center lg:text-left sm:px-4 text-[40px] lg:text-[40px] sm:text-[24px] md:text-[30px] center text-secondary">
                                Program Curriculum
                            </div>
                            <div className="w-[100%] lg:pb-10 flex sm:block sm:p-4 md:flex">
                                <div className="w-[70%] sm:w-[100%] mdsm:w-[100%] lg:w-[70%] md:w-[100%]">
                                    <p id="curriculum" className="text-text-color text-base sm:text-[14px] leading-[30px] text-left"></p>
                                    {/* <p className="text-text-color  sm:text-[14px] leading-[30px] text-left">{MBADM?.program_curriculum}</p> */}
                                    <div className=" sm:px-1 pt-4 sm:pt-0">
                                        <div className="w-[100%] flex lg:flex mdsm:block sm:block ">
                                            <div className="w-[50%] group mdsm:w-[100%] lg:w-[50%] sm:w-[100%] ">
                                                {/* <h4 className="text-text-color Transition  pb-2 text-left pt-2 sm:text-[16px] title text-[24px]">First Year to Fifth Year</h4> */}
                                                {/* <p className="text-text-color text-left text-base pb-5 sm:text-[14px]">As a Major  Specialization, you can opt for either of the following</p> */}
                                                <div className="px-4 ">
                                                    {MBADM?.major_specialization.map((v, i) => {
                                                        return (
                                                            <div key={i} className="w-[100%] pb-6  flex">
                                                                <div className="w-[10%] sm:w-[8%]">
                                                                    <BsCheck2All className="text-h3 text-primary sm:text-h5" />
                                                                </div>
                                                                <div className="w-[90%]">
                                                                    <p className="text-text-color text-base sm:text-sm text-left">
                                                                        {v}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        )
                                                    })}


                                                </div>
                                            </div>
                                            <div className="w-[50%]   group mdsm:w-[100%] lg:w-[50%] sm:w-[100%] lg:pl-4">
                                                {/* <h4 className="text-text-color group-hover:texct Transition  pb-2 text-left pt-2 sm:text-[16px] lg:pt-2 mdsm:pt-0 sm:pt-0 title text-[24px]">Pharmaceutical Analysis</h4> */}
                                                <div className="">
                                                    {/* <p className="text-text-color text-base text-left pb-5 sm:text-[14px]">As a Minor Specialization, you can opt for either of the following</p> */}
                                                    <div className="px-4">
                                                        {MBADM?.minor_specialization.map((v, i) => {
                                                            return (
                                                                <div key={i} className="w-[100%] pb-6 flex">
                                                                    <div className="w-[10%] sm:w-[8%]">
                                                                        <BsCheck2All className="text-h3 text-primary sm:text-h5" />
                                                                    </div>
                                                                    <div className="w-[90%]">
                                                                        <p className="text-text-color text-base sm:text-sm text-left">
                                                                            {v}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <p className="text-text-color text-base text-left pb-5 sm:text-[14px]">Internship or residency training, including postings in specialty units is provided in the Sixth Year</p>

                                        {/* <div className="flex w-[100%] lg:w-[100%]">
                                            <div className="w-[50%] group mdsm:w-[100%] lg:w-[100%] sm:w-[100%] ">
                                                <h4 className="text-text-color Transition  pb-2 text-left pt-2 sm:text-[16px] title text-[24px]">Sixth Year</h4>
                                                <p className="text-text-color text-base text-left pb-5 sm:text-[14px]">Internship or residency training, including postings in specialty units. The student should independently provide clinical pharmacy services to the allotted wards.</p>
                                                <div className="px-4 ">
                                                    {MBADM?.semester4.map((v, i) => {
                                                        return (
                                                            <div>
                                                                <div keys={i} className="w-[100%] pb-6  flex">
                                                                    <div className="w-[10%] sm:w-[8%]">
                                                                        <BsCheck2All className="text-h3 text-primary sm:text-h5" />
                                                                    </div>
                                                                    <div className="w-[90%]">
                                                                        <p className="text-text-color text-base sm:text-sm text-left">
                                                                            {v}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                        </div> */}



                                        <div className="py-3 pt-8 pb-16 sm:pt-2 mx-2">
                                            <div className="w-[100%] sm:px-6 lg:mx:auto flex sm:block mdsm:block  lg:flex">
                                                <div className=" sm:pb-4  mdsm:pb-6 lg:pb-0 mdsm:mr-10">
                                                    <div className="relative ">
                                                        <div className="text-text-color  pt-2 text-h4 sm:text-sm information text-left leading-[30px] ">
                                                            For information about the <span className="font-semibold title text-[22px] sm:text-[18px] tracking-wider text-primary">Curriculum</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className=" sm:w-[100%] flex justify-between">



                                                    <a href="#form">
                                                        <button className="bg-primary  Transition text-[18px] sm:text-[16px] borderradius hover:bg-[#081224] text-pureWhite  px-14 py-2 tracking-wider ">
                                                            Download Brochure
                                                        </button>
                                                    </a>
                                                </div>

                                            </div>


                                        </div>



                                    </div>


                                </div>
                                <div className="w-[30%] px-6 NoneCss">
                                    <img src={Curriculum} alt="Curriculum " />

                                </div>

                            </div>
                        </div>


                    </div>
                </section>
                {/* <ALumnii /> */}

                {/*Curriculum Section  */}

                {/*Learn from Expect Section  */}

                {/* <section className="category-title" id="learn-from-expect">
                        
                        <div className="bg-primary">
                            <div className="LearnExectSection container  mx-auto">

                                <div className="sm:pt-16 title sm:text-[24px] mdsm:text-[30px] lg:pt-16  text-pureWhite tracking-wider lg:text-[40px]">
                                    Learn from Expect
                                </div>



                                <div className="flex w-[100%] sm:block mdsm:block lg:flex ">
                                    <div className="w-[30%] sm:w-[100%] mdsm:w-[100%] lg:w-[30%] mdsm:pt-6 sm:py-6 sm:px-4 center px-10">
                                        <p className="text-pureWhite sm:text-left text-[20px] sm:text-[16px] leading-[30px]">
                                        At our institution, we believe in learning from the best. Our expert faculty strive to help and shape students' academic pursuits and to prepare them for success.
                                        </p>
                                    </div>
                                    <div className="w-[100%] sm:w-[100%] mdsm:w-[100%] lg:w-[100%] mdsm:p-4 sm:p-4">
                                        <FacultyProfileSilder faculty={MBADM.faculty} />

                                    </div>
                                </div>
                                <div className="p-6">

                                </div>

                            </div>



                        </div>
                    </section> */}
                {/* <ALumnii/> */}

                {/*Learn from Expect Section  */}


                {/* Internships  */}
                {/* <section>
                            <div className="container mx-auto">
                            <div className="AdmissionSection">
                                <div className="title text-secondary sm:text-[24px] pt-10 pb-4 tracking-wider text-[40px]">
                                Internships
                                </div>
                                </div>

                            </div>
                        </section> */}
                {/* Internships  */}

                {/*Eligibility  */}

                {/* <section>
                        <div className="container mx-auto pt-0 pb-4">
                            <div className="">
                                <div className="title text-secondary mdsm:text-[30px] sm:text-[24px] pt-8 pb-4 tracking-wider lg:text-[40px]">
                                    Eligibility
                                </div>


                                <div className="w-[100%] lg:flex sm:block md:block pt-8">
                                    <div className="lg:w-[60%] md:w-[100%] sm:w-[100%] px-4">
                                        <div className="w-[100%] pb-6  flex">
                                            <div className="w-[10%] sm:w-[8%]">
                                                <BsCheck2All className="text-h3 text-primary sm:text-h5" />
                                            </div>
                                            <div className="w-[90%]">
                                                <p className="text-text-color text-base sm:text-sm text-left">
                                                Pass in HSC. / Equivalent Examination
                                                </p>
                                            </div>
                                        </div>


                                    </div>
                                    <div className="lg:w-[40%] md:w-[100%] sm:w-[100%] lg:pt-0 md:pt-4 md:px-4  lg:md-0 sm:pt-4 sm:px-4 lg:px-16">
                                        <img src={Eligibility} alt="Eligibility" />

                                    </div>

                                </div>




                            </div>

                        </div>
                    </section> */}

                {/*Eligibility  */}


                {/*Learn from Admission Section  */}

                <section className="category-title mx-4" id="admission">
                    <div className="container mx-auto sm:py-0">
                        <div className="AdmissionSection">

                            <div className="title text-secondary sm:text-[24px] mdsm:text-[30px] sm:pb-0 mdsm:text-30px] pt-10 pb-4 tracking-wider lg:text-[40px]">
                                Eligibility & Admission Process
                            </div>

                            <div className="sm:px-4">


                                <h4 className="text-text-color group-hover:texct Transition  pb-2 text-left pt-2 sm:pt-4 sm:text-[18px] lg:pt-2 mdsm:pt-0  title text-[24px]">Eligibility </h4>
                                <p className="text-text-color text-h5 text-left lg:text-base md:text-[16px] mdsm:text-[16px] sm:text-sm sm:pb-2">Candidates must pass 10+2 with physics and chemistry as compulsory subjects
                                    and any one subject as optional, like mathematics, biology, or computer
                                    science, with 50% marks in the qualifying examination in the academic
                                    stream (or) any other equivalent qualification approved by the university.</p>
                                {/* <p className="text-text-color text-h5 text-left lg:text-base md:text-[16px] mdsm:text-[16px] sm:text-sm"> Equal admission opportunity is provided to all deserving candidates to enroll in the Master of Social Work course at RVS College of Arts and Science.</p> */}
                            </div>

                            <h4 className="text-text-color group-hover:texct Transition mt-10 pb-2 text-left pt-2 sm:text-[18px] lg:pt-2 mdsm:pt-0 sm:pt-0 title text-[24px]">Admission Process </h4>
                            <div className="w-[100%] flex sm:block md:flex-wrap mdsm:flex-wrap sm:p-6 mt-3">
                                <div className="w-[33%] sm:w-[100%] mdsm:w-[50%] md:w-[50%] lg:w-[33%] sm:p-4 pr-8 ">
                                    <div className="w-[100%] flex">
                                        <div className="w-[25%]">
                                            <div className="h-[66px]  text-[20px] bg-primary pt-[10px] tracking-wider font-bold text-white w-[60px]">
                                                01
                                            </div>
                                        </div>
                                        <div className="w-[75%] place-self-center pt-[36px] sm:text-[18px] title text-left text-text-color text-[22px]">
                                            Verify Your Eligibility
                                        </div>
                                    </div>
                                    <p className="text-text-color text-base text-left py-8 sm:py-4  sm:text-[14px] leading-[30px]">
                                        Confirm that you meet the eligibility criteria for the program.

                                    </p>

                                </div>
                                <div className="w-[33%] sm:w-[100%] mdsm:w-[50%] md:w-[50%] lg:w-[33%] sm:p-4 pr-8">
                                    <div className="w-[100%] flex">
                                        <div className="w-[25%]">
                                            <div className="h-[66px]  text-[20px] bg-primary pt-[10px] tracking-wider font-bold text-white w-[60px]">
                                                02
                                            </div>
                                        </div>
                                        <div className="w-[75%] place-self-center pt-[0px] sm:text-[18px] title text-left text-text-color text-[22px]">
                                            Fill out the Application Form

                                        </div>
                                    </div>
                                    <p className="text-text-color text-base text-left py-8 sm:py-4  sm:text-[14px] leading-[30px]">
                                        Complete and submit the application form, providing all the necessary information and supporting documents.

                                    </p>

                                </div>
                                <div className="w-[33%] sm:w-[100%] mdsm:w-[50%] md:w-[50%] lg:w-[33%] sm:p-4 pr-8">
                                    <div className="w-[100%] flex">
                                        <div className="w-[25%]">
                                            <div className="h-[66px]  text-[20px] bg-primary pt-[10px] tracking-wider font-bold text-white w-[60px]">
                                                03
                                            </div>
                                        </div>
                                        <div className="w-[75%]  place-self-center pt-[0px] sm:text-[18px] title text-left text-text-color text-[22px]">
                                            Verify Your Educational Credentials
                                        </div>
                                    </div>
                                    <p className="text-text-color text-base text-left py-8 sm:py-4  sm:text-[14px] leading-[30px]">
                                        Verify your educational credentials by providing the required certificates and transcripts.

                                    </p>

                                </div>

                            </div>

                            {/* <div className="py-3 pt-2 pb-14 sm:pt-2">
                                <div className="w-[100%] pb-3 sm:px-6 lg:mx:auto flex sm:block mdsm:flex lg:flex">
                                    <div className=" sm:pb-4 mdsm:pb-6 lg:pb-0 mdsm:mr-10">
                                        <div className="relative ">
                                            <div className="text-text-color  pt-2 text-h4 sm:text-sm information text-left leading-[30px]">
                                                For <span className="font-semibold title text-[22px] sm:text-[18px] tracking-wider text-primary">Admissions, Contact,</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="text-left sm:w-[100%]">
                                    <p className="text-text-color text-h4 font-semibold">Principal, RVS College of Pharmaceutical Sciences,</p>
                                    <p className="text-text-color">242, Trichy Road, Sulur, Coimbatore</p>
                                    <p className="text-text-color"><span className="text-primary font-semibold">Phone -</span> 9500416761, 9443808361</p>
                                    <p className="text-text-color"><span className="text-primary font-semibold">Email ID -</span> <a href="mailto:pharmacy_office@rvsgroup.com">pharmacy_office@rvsgroup.com</a></p>
                                    <p className="text-text-color"><span className="text-primary font-semibold">Visit us -</span> <a href="https://rvscops.ac.in/" rel="noreferrer" target="_blank" className="hover:text-primary hover:underline hover:underline-offset-4">www.rvscops.ac.in</a></p>
                                </div>
                                <p className="text-left text-h3 lg:pl-0 sm:text-h4 sm:pl-3 sm:w-[80%] md:pl-3 text-secondary font-semibold pt-6">We also provide B. Pharm & M. Pharm</p>
                            </div> */}

                            <div className="py-3 pt-2 pb-14 sm:pt-2">
                                <div className="w-[100%] sm:px-6 lg:mx:auto flex sm:block mdsm:block lg:flex">
                                    <div className=" sm:pb-4  mdsm:pb-6 lg:pb-0 mdsm:mr-10">
                                        <div className="relative ">
                                            <div className="text-text-color  pt-2 text-h4 sm:text-sm information text-left leading-[30px]">
                                                For more information on Admission and <span className="font-semibold title text-[22px] sm:text-[18px] tracking-wider text-primary">Fee Structure</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="sm:w-[100%] flex justify-between">
                                        <a href="#form">
                                            <button className="bg-primary sm:text-[16px] Transition text-[18px] borderradius hover:bg-[#081224] text-pureWhite  px-14 py-2 tracking-wider ">
                                                APPLY NOW
                                            </button>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <p className="text-left text-h3 lg:pl-0 sm:text-h4 sm:pl-3 sm:w-[80%] md:pl-3 text-secondary font-semibold pt-6">We also provide B. Pharm & M. Pharm</p>
                        </div>

                    </div>

                </section>

                {/*Learn from Admission Section  */}


                {/*Learn from Internship Section  */}
                {/* <section className="internShip bg-primary ">
                        <div className="container mx-auto">
                            <Alumni />

                        </div>


                    </section> */}

                {/*Learn from Internship Section  */}

                {/*Learn from FAQ Section  */}
                {/* <section className="category-title" className="container mx-auto pb-14 sm:pb-0">
                        <div className="pt-10 pb-14 sm:pt-4 sm:pb-4">
                            <div className=" FAQsection" className="category-title" id="FAQsection">
                                <div className="title sm:text-[24px] mdsm:text-[30px] text-secondary tracking-wider lg:text-[40px]">
                                    FAQ
                                </div>

                                <div className="w-[100%] flex sm:block mdsm:block lg:flex">
                                    <div className="w-[50%] sm:w-[100%] mdsm:w-[100%] lg:w-[50%]">
                                        <div className="py-12 sm:px-2 sm:py-4">


                                            <ul className="accordion">
                                                {faqs.map((faq, index) => (
                                                    <AccordionItem
                                                        key={index}
                                                        onToggle={() => handleToggle(index)}
                                                        active={clicked === index} faq={faq}
                                                    />
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="w-[50%] sm:w-[100%] mdsm:w-[100%] px-8 py-16 lg:w-[50%] lg:py-16 sm:py-2 mdsm:py-2 sm:px-4">
                                        <img src={FAQ} alt="FAQ" className="mdsm:w-[100%]" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="sm:p-16">

                        </div>
                    </section> */}




                {/*Learn from FAQ Section  */}


                {/*Learn from Footer Section  */}

                <footer id="footer" className="category-title" ref={scrollHideDiv}>

                    <div className="py-6 pb-12 bg-[#151515] text-white ">
                        <div className="container mx-auto">
                            <div className="w-[100%] flex sm:px-4">
                                <div className="w-[70%] sm:w-[0%] mdsm:w-[0%]">
                                </div>
                                <div className="w-[30%] sm:w-[100%] mdsm:w-[100%]">
                                    <div className="text-h2 text-pureWhite sm:text-h4 text-left pb-2 tracking-wider pt-10 sm:text-left title">Location</div>
                                    <div className="block text-left sm:text-sm leading-[26px] tracking-wide">
                                        Unit III - RVS Health Science Campus
                                        <br />242, Trichy Road, Sulur,
                                        <br /> Coimbatore - 641 402.
                                    </div>
                                </div>

                            </div>
                            <p className="text-center pt-5 text-[12px] transformtech tracking-wider text-white">© {date} RVS College of Pharmaceutical Sciences Coimbatore. All rights reserved</p>

                            <hr className="h-[1px] text-white" />
                            <h2 className="text-white pt-3 text-center tracking-wider transformtech text-[12px]">Designed, Developed and Maintained By Transform Tech</h2>
                            <h3 className="text-white pb-3 text-center tracking-wider transformtech text-[12px]">A Unit of RVS Educational Trust</h3>

                        </div>
                        {/* copyright@rvsgroup.com */}



                    </div>
                </footer>


                {/*Learn from Footer Section  */}

                <section id="scrolling" className="sticky shadow bg-[#fff] bottom-0 z-10  w-[100%]">
                    <div className=" container mx-auto py-6 w-[100%]">
                        <div className="flex justify-end sm:justify-center w-[100%]">
                            <div className="w-[70%] download-broc">
                                <h1 className="text-[30px] text-left text-primary font-semibold  tracking-wide leading-[56px] transformtech">To know more about our <span className="transformtech text-[30px] text-left text-primary font-semibold  tracking-wide leading-[56px]">Placement Support</span> </h1>
                            </div>
                            <div className="lg:w-[30%] sm:w-[100%] md:w-[100%] mdsm:w-[100%]">
                                <a href="#form">
                                    <button className="bg-primary  Transition text-[18px] borderradius hover:bg-[#081224] text-pureWhite  px-14 py-4 tracking-wider">Download Brochure</button>
                                </a>
                            </div>

                        </div>
                    </div>
                </section>
            </div>
        </>

    )
}

export default CoursePage