
export const MBADM = {
    title: "Doctor of Pharmacy (Pharm.D)",
    course_dis: "The MBA Digital Marketing program with dual specialization offers a comprehensive curriculum covering all aspects of the field of Digital Marketing and a minor specialization. It provides students with the latest tactics and approaches to succeed in the digital landscape. The program offers internships and live projects to give students a competitive edge in the rapidly evolving digital marketing industry. With the option to choose a minor specialization, the course enables students to enhance their employability in a different domain based on their specialization in the current competitive job market.",
    course_reg: {
        course_start: "August 2023",
        duration: "2 Years Full time",
        location: "Sulur, Coimbatore",
        specialization: "Human Resource Management, Medical & Psychiatric Social Work & Community Development",
    },
    scholarships: ["Merit", "Sports", "Extra Curricular Activities ", "Defence", " Ex-Serviceman", "Community"],
    about_course: "The two-year MBA in Digital Marketing program offered by RVS IMSR is designed to equip students with the necessary skills and knowledge to succeed in the rapidly growing Digital Marketing Industry. The program covers various techniques of SEO, Content Marketing, SEM, and Social Media Marketing. The faculty comprises certified professionals from top business schools, ensuring a comprehensive and up-to-date curriculum. Students are provided with the creativity and communication skills needed to explore various career opportunities in the online marketing field. The course is ideal for individuals looking to advance their careers or explore business options in India and worldwide.",
    // programme:["Who wants to create a secure future in Digital Marketing.","Who wishes to learn more about Digital Marketing to make a mark in the field.",]
    program_curriculum: `MBA in Digital Marketing program provides a comprehensive education in the field, equipping you with the latest techniques and strategies to succeed in today's digital world. With dual specialization options and opportunities for internships and live projects, you'll have the competitive edge to advance your career in the fast-growing digital marketing industry.`,
    major_specialization: ["Human Anatomy and Physiology", "Pharmaceutics", "Pharmacognosy & Phytopharmaceuticals", "Pharmacology", "Medicinal Chemistry", "Pharmaceutical Analysis", "Pathophysiology",],
    minor_specialization: ["Pharmaceutical Microbiology", "Pharmacotherapeutics", "Community Pharmacy", "Hospital Pharmacy", "Clinical Pharmacy", "Pharmaceutical Jurisprudence", "Remedial Mathematics/Biology"],
    pharmacology: ["Modern Pharmaceutical Analytical Techniques", "Advanced Pharmacology", "Pharmacological and Toxicological Screening Methods", "Cellular and Molecular Pharmacology", "Principles of Drug Discovery"],
    semester3: ["Research Methodology and Biostatistics", "Journal Club", "Discussion / Presentation(Proposal Presentation)", "Research Work"],
    semester4: ["Six months in the General Medicine department, and ", "Two months each in three other specialty departments"],
    learn_from_experts: "Our institution is a residential college where students, scholars, staff members, and alums come together for academic pursuits. Our deep-rooted history serves as the cornerstone of our principles and beliefs.",

}